<template>
  <div>
    <div class="e-breadcrumb">订单管理</div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <div class="e-card-body" style="padding-top:35px">
          <div class="e-card-tabs">
            <div class="e-tabs-wrapper">
              <div class="e-tabs-item " :class="{'active':tabIndex==0}" @click="switchTable(0)">全部
              </div>
              <div class="e-tabs-item" :class="{'active':tabIndex==1}" @click="switchTable(1)">待支付
              </div>
              <div class="e-tabs-item" :class="{'active':tabIndex==2}" @click="switchTable(2)">待发货
              </div>
              <div class="e-tabs-item" :class="{'active':tabIndex==3}" @click="switchTable(3)">已发货
              </div>
              <div class="e-tabs-item" :class="{'active':tabIndex==4}" @click="switchTable(4)">退款中
              </div>
              <div class="e-tabs-item" :class="{'active':tabIndex==5}" @click="switchTable(5)">已退款
              </div>
            </div>
          </div>
          <div style="margin-bottom: 35px">
            <div class="handle-box">
              <span class="v-form-label">订单编号:</span>
              <el-input placeholder="订单编号" size="small" style="width:265px" v-model="orderNo" clearable
                        class="handle-input mr10"></el-input>
              <el-row type="flex" justify="end" style="margin-left: 30px">
                <el-button size="small" class="e-button" type="primary" @click="searchEvent">查询
                </el-button>
              </el-row>
            </div>
          </div>
          <div class="e-table-wrapper">
            <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
              <thead>
              <tr>
                <th width="130" class="th-l">订单编号</th>
                <th width="100" class="th-l">下单人</th>
                <th width="130" class="th-l">订单类型</th>
                <th width="130" class="th-l">订单渠道</th>
                <th width="130" class="th-l">门店</th>
                <th width="90" class="th-l">订单状态</th>
                <th width="130" class="th-l">支付金额</th>
                <th width="90" class="th-l">支付状态</th>
                <th width="160" class="th-l">支付时间</th>
                <th width="160" class="th-l">创建时间</th>
                <th width="200" class="th-l">操作</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row,index) in list" :key="row.id" :class="{ on: index % 2 == 0, off: index % 2 != 0 }">
                <td class="td-l"><span>{{row.orderNo}}</span></td>
                <td class="td-l"></td>
                <td class="td-l"></td>
                <td class="td-l"></td>
                <td class="td-l"></td>
                <td class="td-l">
                  <span v-if="row.orderStatus==1">待支付</span>
                  <span v-if="row.orderStatus==2">待发货</span>
                  <span v-if="row.orderStatus==3">已发货</span>
                  <span v-if="row.orderStatus==4">退款中</span>
                  <span v-if="row.orderStatus==5">已款中</span>
                </td>
                <td class="td-l">{{row.payAmount}}</td>
                <td class="td-l">
                  <span v-if="row.payStatus==0">未支付</span>
                  <span v-if="row.payStatus==1">已支付</span>
                </td>
                <td class="td-l">{{row.payTime}}</td>
                <td class="td-l" style="width: 150px">{{row.createTime}}</td>
                <td class="td-l" style="width: 200px">
                  <el-button type="text" icon="el-icon-edit" @click="toSkuDetailed(index)">明细</el-button>
                  <el-button type="text" icon="el-icon-delete" class="red" @click="removeEvent(index)">删除</el-button>
                </td>

              </tr>
              </tbody>
            </table>
            <div class="pagination">
              <el-pagination
                background
                @current-change="handleCurrentChange"
                :pager-count="7"
                layout="total, prev, pager, next,jumper"
                :total="pagination.count"
                :page-count="pagination.pages"
                :current-page="pagination.pageNumber"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 删除提示框 -->
    <el-dialog title="提示" :visible.sync="delVisible" width="300px" center>
      <div class="del-dialog-cnt">删除不可恢复，是否确定删除？</div>
      <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="delVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="delBbiEmployee">确 定</el-button>
            </span>
    </el-dialog>

    <!-- 设置部门 -->
    <el-dialog title="订单商品明细" :visible.sync="setVisible" width="1000px">
      <div  >
        <div class="e-card">
          <div class="e-card-body" style="padding-top:10px">
            <div class="e-table-wrapper">
              <table cellpadding="0" cellspacing="0" border="0" width="100%" style="text-align: center">
                <thead>
                <tr>
                  <th width="130" class="th-l">商品编号</th>
                  <th width="130" class="th-l">商品名称</th>
                  <th width="130" class="th-l">单位</th>
                  <th width="130" class="th-l">数量</th>
                  <th width="130" class="th-l">单价</th>
                  <th width="130" class="th-l">小计</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(row,index) in orderSkuList" :key="row.id">
                  <td class="td-l"><span>{{row.skuNo}}</span></td>
                  <td class="td-l"><span>{{row.skuName}}</span></td>
                  <td class="td-l">{{row.skuSpecs}}</td>
                  <td class="td-l">{{unitTypeMap[row.unitType]}}</td>
                  <td class="td-l">{{row.supplierName}}</td>
                  <td class="td-l">
                    {{row.initInventory}}
                  </td>
                  <td class="td-l">
                    <el-input size="small" v-model="row.number" style="width: 80px"></el-input>
                  </td>
                  <td class="td-l">
                    {{row.unitPrice}}
                  </td>
                  <td class="td-l">
                    {{row.unitPrice*row.number}}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>

</template>

<script>

  export default {
    data() {
      return {
        orderNo: '',
        tabIndex: 0,

        list: [],

        pagination: {
          count: 0,
          pageNumber: 0,
          pageSize: 0,
          pages: 1
        },

        setVisible: false,
        delVisible: false,
        editItem: {},

        orderSkuList:[],
      }
    },
    async created() {
      this.searchEvent();
    },
    methods: {
      toSkuDetailed(index) {
        this.setVisible = true
        this.editItem = this.list[index]
        this.getOrderSkuList()
      },
      async getOrderSkuList() {

        let data = {
          orderId: this.editItem.id,
        }
        let res = await this.$post("/admin/getOrderSkuList", data)
        if (res.code == 200) {
          this.orderSkuList = res.orderSkuList;
        }
      },


      switchTable(tabIndex) {
        this.tabIndex = tabIndex
        this.requestSearch()
      },


      async requestSearch(page) {
        let orderStatus = '';
        if (!this.tabIndex == 0) {
          orderStatus = this.tabIndex
        }
        let currentPage = page || 1;
        let data = {
          orderNo: this.orderNo,
          orderStatus: orderStatus,
          isDel: 0,
          size: this.size == '' ? '10' : this.size,
          currentPage
        }

        let res = await this.$get("/admin/getMspOrderForPage", data)
        let list = res.dataSet.list;
        this.list = list;
        this.pagination = {
          count: res.count,
          pageNumber: res.pageNumber,
          pageSize: res.pageSize,
          pages: res.pages
        }
      },
      handleCurrentChange(page) {
        this.requestSearch(page)
      },

      searchEvent() {
        this.requestSearch()
      },

      async removeEvent(index) {
        this.editItem = this.list[index]
        this.delVisible = true;
      },


      //删除BbiEmployee
      async delBbiEmployee() {
        // let data = {
        //   id: this.editItem.id
        // };
        // let res = await this.$post("/admin/delete_bbiEmployee", data)
        // if (res.code == 200) {
        //   this.searchEvent();
        //   this.$message.success(res.message);
        //   this.delVisible = false;
        // } else {
        //   this.$message.error(res.message);
        // }
      },

    },
  }

</script>

<style scoped>
  .th-l {
    text-align: center;
    padding-right: 10px;
    font-size: 14px;
  }

  .td-l {
    padding: 10px 12px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .e-table-wrapper tbody .el-button {
    padding: 3px 0px;
  }
  .off {
  background-color: #fff;
}
.on {
  background-color: #ececea;
}
</style>
